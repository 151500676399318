import { Station } from "../station/station.class";
import { VolumeUom } from '@app/models/common/untitsOfMeasure.class';
import { PaymentInfo } from "./manual-transaction.class";

export class SettlementMetadata {
  id!: string;
  referenceId?: string;
  reportId?: string;
  sequenceStart?: number;
  sequenceEnd?: number;
  periodStart!: Date;
  periodEnd!: Date;
  paymentProviderReference?: string;

  public constructor(init?: Partial<SettlementMetadata>) {
    Object.assign(this, init);
  }
}

export class SettlementMetadataUpdate extends SettlementMetadata {
  stationId?: string;

  public constructor(init?: Partial<SettlementMetadataUpdate>) {
    super(init);
    Object.assign(this, init);
  }
}

export class Settlement extends SettlementMetadata {
  correlationId!: string;
  station!: Station;
  created!: Date;
  createdBy!: string;
  total!: number;
  pointOfSaleReconciliation!: PointOfSaleReconciliation;
  paymentProviderReconciliation!: PaymentProviderReconciliation;
  paymentProviderTotal!: number;
  paymentProviderTotalTransactions!: number;
  hasReconciliationLetter!: boolean;
  lastModified!: Date;
  lastModifiedBy!: string;
  totalTransactions !: number;
  settlementComment?: SettlementComment;
  reconStatus?: 'success' | 'pending' | 'error';
  verificationStatus!: SettlementVerificationStatusFlag;
  resolveStatus?: SettlementResolveStatus;

  // Helper
  isDeleting?: boolean = false;

  public constructor(init?: Partial<Settlement>) {
    super();
    Object.assign(this, init);
  }
}

export class SettlementComment {
  text!: string;
  lastModified?: Date;
  modifiedBy?: string;

  public constructor(init?: Partial<SettlementComment>) {
    Object.assign(this, init);
  }
}

export class Transaction {
  id!: string;
  correlationId!: string;
  amount!: number;
  date!: Date;
  payments!: Payment[];
}

export class Sale {
  id!: string;
  correlationId!: string;
  station!: Station;
  total!: number;
  cardNumber?: string;
  currencyCode!: number;
  dateTimeOfSale!: string;
  isEndOfDay!: boolean;
  payments!: Payment[];
  fuelItems?: FuelItem[];
  items!: Item[];
  attachments?: string[];
  isManual?: boolean;
  created!: string;
  createdBy!: string;
  lastModified!: string;
  lastModifiedBy!: string;
  sequenceNumber?: number;
  terminalId?: string;
  mileage?: string;
  isGeneratingPdf?: boolean = false; //Added to create a spinner for generating pdf receipt
  public constructor(data?: Partial<Sale>) {
    Object.assign(this, data);
  }
}

export class UpdateSale {
  id!: string;
  correlationId!: string;
  station!: Station;
  total!: number;
  cardNumber?: string;
  currencyCode!: number;
  dateTimeOfSale!: string;
  isEndOfDay!: boolean;
  payments!: PaymentInfo[];
  fuelItems!: FuelItem[];
  items!: Item[];
  attachments?: string[];
  isManual?: boolean;
  created!: string;
  createdBy!: string;
  lastModified!: string;
  lastModifiedBy!: string;
  sequenceNumber?: number;

  public constructor(data?: Partial<UpdateSale>) {
    Object.assign(this, data);
  }
}

export class Payment {
  numberOfTransactions!: number;
  amount!: number;
  currency!: number;
  paymentType!: PaymentType;
  cardNumber?: string;
  readMethod?: string;
  paymentProviderInfo?: PaymentProviderInfo;

  public constructor(data?: Partial<Payment>) {
    Object.assign(this, data);
  }
}

export class PaymentType {
  id!: string; // PaymentReferenceId for frontend-endpoint
  correlationId!: string;
  name!: string;
  shortName?: string;
  prefix!: string;
  group!: string;
  indicator!: string;
  isTruck!: boolean;
  billable!: boolean;
  paymentReferenceId!: string;
  customerReferenceId!: string;

  public constructor(data?: Partial<PaymentType>) {
    Object.assign(this, data);
  }
}

class PaymentProviderInfo {
  transactionId!: string;
  receiptId!: string;
  cardNumber!: string;
  authenticationCode!: string;
  dateTimeOfTransaction!: string;
  milage!: number;
  items!: Item[];
}

export class Item {
  id!: string;
  quantity!: Quantity;
  amount!: number;
  unitPrice!: number;
  tax!: Tax;

  public constructor(data?: Partial<Item>) {
    Object.assign(this, data);
  }
}

export class FuelItem {
  id!: string;
  fuelType!: string;
  quantity!: Quantity;
  amount!: number;
  unitPrice!: number;
  tax!: Tax;
  itemGroupId?: string;
  pumpId?: string;

  public constructor(data?: Partial<FuelItem>) {
    Object.assign(this, data);
  }
}
export class Tax {
  code?: string;
  percentage!: number;
  amount!: number;

  public constructor(data?: Partial<Tax>) {
    Object.assign(this, data);
  }
}

export class Quantity {
  measurement!: number;
  unitOfMeasure!: VolumeUom;

  public constructor(data?: Partial<Quantity>) {
    Object.assign(this, data);
  }
}

class PointOfSaleReconciliation {
  dateTimeOfReconciliation!: string;
  errors?: string[];
  status!: ReconciliationStatus;
  id!: string;
  correlationId?: string;
}

class PaymentProviderReconciliation {
  dateTimeOfReconciliation!: string;
  errors?: string[];
  status!: ReconciliationStatus;
  id!: string;
  correlationId?: string;
}

export enum ReconciliationStatus {
  Unset = 0,
  Failed = 1,
  Succeeded = 2
}

export enum SettlementVerificationStatusFlag {
  None = 0,
  Closed = 1 << 0,                              // 1 - 0000 0001
  SaleVerificationReady = 1 << 1,               // 2 - 0000 0010
  PointOfSaleReconReady = 1 << 2,               // 4 - 0000 0100
  PaymentProviderReconReady = 1 << 3,           // 8 - 0000 1000
  PaymentProviderReferenceReceived = 1 << 4,    // 16 - 0001 0000

  All = Closed | SaleVerificationReady | PointOfSaleReconReady | PaymentProviderReconReady | PaymentProviderReferenceReceived
}

export enum SettlementResolveStatus {
  Unset = 0,
  Rerun = 1,
  Accepted = 2,
  Eod = 3
}

export const SettlementVerificationStatus = {
  isSet(status: SettlementVerificationStatusFlag, flag: SettlementVerificationStatusFlag) {
    return (status & flag) === flag;
  }
};
