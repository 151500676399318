import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginatedList } from '@models/common/paginated-list.class';
import { ReconciliationStatus, PaymentType, Sale, Settlement, SettlementMetadataUpdate } from '@models/settlement/settlement.class';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DeviationComment } from '@models/deviation/deviationComment.class';
import { ManualTransaction } from '@app/models/settlement/manual-transaction.class';
import { Reconciliation } from '@app/models/settlement/reconciliation.class';
import { UpdateSaleDto } from '@app/models/settlement/update-sale-dto.class';
import { DeviationTransactions } from '@app/models/deviation/deviationTransactions.class';

@Injectable()
export class SettlementService {
  private uri: string = `${environment.routes.apiEndpoint}/settlement`;

  constructor(private http: HttpClient) { }

  public getSettlement(SettlementId: string): Observable<Settlement> {
    return this.http.get<Settlement>(`${this.uri}/settlements/${SettlementId}`);
  }

  /**
   * Returns an observable of all settlements
   * @param pageSize PageSize for query - can be MatPaginator pageSize
   * @param pageIndex PageIndex for query - can be MatPaginator pageIndex
   * @returns
   */
  public getSettlements(
    sortField: string, sortDescending: boolean,
    startDate: Date | null, endDate: Date | null,
    reconStatuses: ReconciliationStatus[] = [],
    pageSize: number = 10, pageIndex: number = 0,
    stationIds: string[] | null = [], stationGroupIds: string[] | null = []
  ): Observable<PaginatedList<Settlement>> {

    let filters = `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    if (sortField) filters += `&SortByField=${sortField}`;
    if (sortDescending != null) filters += `&SortDescending=${sortDescending}`;

    if (startDate != null) filters += `&PeriodFrom=${startDate.toISOString()}`;
    if (endDate != null) filters += `&PeriodTo=${endDate.toISOString()}`;
    if (reconStatuses?.length) filters += `&ReconciliationStatuses=${reconStatuses.join('&ReconciliationStatuses=')}`;
    if (stationIds?.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (stationGroupIds?.length) filters += `&StationGroupIds=${stationGroupIds.join('&StationGroupIds=')}`;

    return this.http.get<PaginatedList<Settlement>>(`${this.uri}/settlements?${filters}`);
  }

  /**
   * Returns an observable of settlements for a station
   * @param stationId Station to get settlements for
   * @param pageSize PageSize for query - can be MatPaginator pageSize
   * @param pageIndex PageIndex for query - can be MatPaginator pageIndex
   * @returns
   */
  public getSettlementsForStation(stationId: string, pageSize: number = 10, pageIndex: number = 0, sortField: string, sortDescending: boolean, startDate: Date | null, endDate: Date | null): Observable<PaginatedList<Settlement>> {
    let url = `${this.uri}/settlements?StationIds=${stationId}`
      + `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`
      + `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    if (startDate != null) url += `&PeriodFrom=${startDate.toISOString()}`;
    if (endDate != null) {
      // Avoid modifying the passed in Date object
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);
      url += `&PeriodTo=${endDate.toISOString()}`;
    }
    return this.http.get<PaginatedList<Settlement>>(url);
  }

  /**
   * Returns an observable of sales for a station
   * @param stationId Station to get sales for
   * @param pageSize PageSize for query - can be MatPaginator pageSize
   * @param pageIndex PageIndex for query - can be MatPaginator pageIndex
   * @returns
   */
  public getSalesForStation(stationId: string, pageSize: number = 10, pageIndex: number = 0, sortField: string, sortDescending: boolean, startDate: Date | null, endDate: Date | null): Observable<PaginatedList<Sale>> {
    let url = `${this.uri}/sales?StationId=${stationId}`
      + `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`
      + `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    if (startDate != null) url += `&PeriodFrom=${startDate.toISOString()}`;
    if (endDate != null) {
      endDate.setDate(endDate.getDate() + 1);
      url += `&PeriodTo=${endDate.toISOString()}`;
    }

    return this.http.get<PaginatedList<Sale>>(url);
  }

  public postSettlementMetadata(settlement: SettlementMetadataUpdate) {
    return this.http.post(`${this.uri}/settlements`, settlement);
  }

  public getSales(
    sortField: string, sortDescending: boolean,
    pageSize: number = 10, pageIndex: number = 0,
    settlementId: string | null = null, stationIds: string[] | null = null, stationGroupIds: string[] | null = null,
    startDate: Date | null, endDate: Date | null,
    priceRangeFilter: { min: number, max: number } | null = null,
    paymentMethods: string[] | null = null, fuelIds: string[] | null = null,
    filterByIsManual: boolean | null = null, filterByIsEod: boolean | null = null
  ): Observable<PaginatedList<Sale>> {
    let filter = `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}&SortByField=${sortField}&SortDescending=${sortDescending}`;

    if (settlementId) filter += `&SettlementId=${settlementId}`;
    if (startDate) {
      startDate.setHours(0, 0, 0, 0);
      filter += `&dateFrom=${startDate.toISOString()}`;
    }
    if (endDate) {
      endDate.setHours(23, 59, 59, 999);
      filter += `&dateTo=${endDate.toISOString()}`;
    }
    if (filterByIsManual) filter += `&IsManual=${filterByIsManual}`;
    if (filterByIsEod) filter += `&IsEod=${filterByIsEod}}`;
    if (stationIds && stationIds.length) filter += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (stationGroupIds && stationGroupIds.length) filter += `&StationGroupIds=${stationGroupIds.join('&StationGroupIds=')}`;
    if (paymentMethods && paymentMethods.length) filter += `&PaymentMethods=${paymentMethods.join('&PaymentMethods=')}`;
    if (fuelIds && fuelIds.length) filter += `&FuelIds=${fuelIds.join('&FuelIds=')}`;
    if (priceRangeFilter) filter += `&PriceFrom=${priceRangeFilter.min}&PriceTo=${priceRangeFilter.max}`;

    return this.http.get<PaginatedList<Sale>>(`${this.uri}/sales?${filter}`);
  }

  public getSalesBySequence(
    sequenceStart: number, sequenceEnd: number,
    settlementStartDate: Date, settlementEndDate: Date,
    filterByIsManual: boolean, filterByIsEod: boolean,
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = "dateTimeOfSale", sortDescending: boolean = true
  ): Observable<PaginatedList<Sale>> {
    let filters = `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}&SortByField=${sortField}&SortDescending=${sortDescending}`;
    if (filterByIsManual) filters += `&IsManual=${filterByIsManual}`;
    if (filterByIsEod) filters += `&IsEod=${filterByIsEod}`;

    if (sequenceStart) {
      filters += `&SequenceStart=${sequenceStart}`;
      let filterDate: Date;
      if (settlementStartDate) filterDate = new Date(settlementStartDate);
      else if (settlementEndDate) filterDate = new Date(settlementEndDate);
      else filterDate = new Date();
      // Subtract 2 days from the date to make sure we overlap with SequenceStart
      filters += `&DateFrom=${new Date(filterDate.getTime() - 2 * 24 * 60 * 60 * 1000).toISOString()}`;
    }
    if (sequenceEnd) filters += `&SequenceEnd=${sequenceEnd}`;

    return this.http.get<PaginatedList<Sale>>(`${this.uri}/sales?${filters}`);
  }

  public getSalesBySettlementId(
    settlementId: string,
    filterByIsManual?: boolean, filterByIsEod?: boolean,
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = "dateTimeOfSale", sortDescending: boolean = true
  ): Observable<PaginatedList<Sale>> {
    let filters = `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}&SortByField=${sortField}&SortDescending=${sortDescending}`;
    if (filterByIsManual) filters += `&IsManual=${filterByIsManual}`;
    if (filterByIsEod) filters += `&IsEod=${filterByIsEod}`;

    if (settlementId) filters += `&SettlementId=${settlementId}`;

    return this.http.get<PaginatedList<Sale>>(`${this.uri}/sales?${filters}`);
  }


  public upsertSettlementComment(settlementId: string, deviationComment: string) {
    const body = { id: settlementId, comment: deviationComment } as DeviationComment;
    return this.http.put(`${this.uri}/settlements/${settlementId}/comment`, body);
  }

  // Posibility to search for a spesific date that matches the settlements periodEnd.
  public getAllSettlementsByStatus(
    status: number,
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string | null = null, sortDescending: boolean = true,
    periodEnd: Date | null = null, periodTo: Date | null = null
  ): Observable<PaginatedList<Settlement>> {
    let filters = `&ReconciliationStatuses=${status}&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    if (periodEnd != null) filters += `&PeriodEnd=${periodEnd.toISOString()}`;
    if (periodTo != null) filters += `&PeriodTo=${periodTo.toISOString()}`;
    if (sortField != null) filters += `&SortByField=${sortField}`;
    if (sortDescending != null) filters += `&SortDescending=${sortDescending}`;

    return this.http.get<PaginatedList<Settlement>>(`${this.uri}/settlements?${filters}`);
  }

  public acceptDeviation(body: { message: string }, settlementId: string) {
    const url = `${this.uri}/settlements/${settlementId}/reconciliations/accept`;
    return this.http.post(url, body);
  }

  public getAcceptedDeviations(pageSize: number = 10, pageIndex: number = 0, sortField: string, sortDescending: boolean): Observable<PaginatedList<Settlement>> {
    const url = `${this.uri}/settlements?AcceptedDeviations=true&ReconciliationStatuses=${ReconciliationStatus.Succeeded}`
      + `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`
      + `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    return this.http.get<PaginatedList<Settlement>>(url);
  }

  public getFailedTransactionsForSettlement(settlementId: string) {
    const url = `${this.uri}/settlements/${settlementId}/deviations`;
    return this.http.get<DeviationTransactions>(url);
  }

  public rerunSettlement(settlementId: string, body: { message: string }) {
    const url = `${this.uri}/settlements/${settlementId}/reconciliations/rerun`;
    return this.http.post(url, body);
  }

  public getPaymentTypes = () => this.http.get<PaginatedList<PaymentType>>(`${this.uri}/paymentTypes/frontend?PageSize=50`);

  public getPaymentTypesWithFullDataset = () => this.http.get<PaginatedList<PaymentType>>(`${this.uri}/paymentTypes?PageSize=1000`);

  public postManualTransaction(transaction: ManualTransaction) {
    const url = `${this.uri}/sales`;
    return this.http.post(url, transaction);
  }

  public updateTransaction(transaction: ManualTransaction) {
    const url = `${this.uri}/sales/${transaction.id}`;
    return this.http.put(url, transaction);
  }

  public updateSale(sale: UpdateSaleDto) {
    const url = `${this.uri}/sales/${sale.id}`;
    return this.http.put(url, sale);
  }

  public deleteSale(saleId: string) {
    const url = `${this.uri}/sales/${saleId}`;
    return this.http.delete(url);
  }

  public forceRunReconciliation(settlementId: string) {
    const url = `${this.uri}/settlements/${settlementId}/reconciliations/forceRun`;
    return this.http.post(url, null);
  }

  public getReconciliation(reconciliationId: string) {
    const url = `${this.uri}/settlements/reconciliations/${reconciliationId}`;
    return this.http.get<Reconciliation>(url);
  }

  public deleteSettlement(settlementId: string) {
    const url = `${this.uri}/settlements/${settlementId}`;
    return this.http.delete(url);
  }
}
