import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable, lastValueFrom } from "rxjs";
import { SnackbarService } from "../services/snackbar.service";
import { GenerateReceiptDto } from "@app/models/settlement/generateReceipt";

@Injectable()
export class DocumentService {
  private uri: string = `${environment.routes.apiEndpoint}/document`;

  constructor(private http: HttpClient, private snackbarService: SnackbarService) { }

  public getReconLetterUrl(stationCorrelationId: string, settlementId: string): Observable<string> {
    return this.http.get<string>(`${this.uri}/file/reconciliationLetter?StationId=${stationCorrelationId}&SettlementId=${settlementId}&Output=0`,
      { responseType: 'text' as 'json' });
  }

  public getDailyReportUrl(settlementId: string): Observable<string> {
    return this.http.get<string>(`${this.uri}/documents/${settlementId}-DailyReport.pdf`,
      { responseType: 'text' as 'json' });
  }

  public getReceiptUrl(receiptName: string): Observable<string> {
    return this.http.get<string>(`${this.uri}/file/receipt?FileName=${receiptName}`,
      { responseType: 'text' as 'json' })
  }

  public getDocument(url: string) {
    return this.http.get(url,
      { responseType: 'blob' }
    );
  }

  public generatePdfReceipt(receiptDto: GenerateReceiptDto): Observable<Blob> {
    return this.http.post(`${this.uri}/generate/pdfReceipt`, receiptDto, { responseType: 'blob' });
  }

  public uploadReceiptsToBlob = async (receipts: FormData[]): Promise<string[]> => {
    const promises = receipts.map(async receipt => await lastValueFrom(this.http.post<string>(`${this.uri}/file/receipt?`, receipt, { responseType: 'text' as 'json' })));

    try {
      const receiptNames = await Promise.all(promises);
      return receiptNames;
    }
    catch {
      this.snackbarService.open('manualTransactions.receiptError', 'error');
      return [];
    }
  }

  public deleteDocument(fileName: string) {
    return this.http.delete(`${this.uri}/documents/${fileName}`);
  }
}